import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { firebaseApp } from "Components/Firebase";
import {
  CollectionReference,
  DocumentReference,
  collection,
  doc,
  getFirestore,
  query,
} from "firebase/firestore";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import Background from "Assets/Images/Background-splatter.png";
import AthleteRight from "Assets/Images/athleteRightSide.png";
import AthleteLeft from "Assets/Images/athleteLeftSide.png";
import CalloutHeadline from "Assets/Images/Logo-LockUp.png";
import FordTexasLogo from "Assets/Images/FordLogo.png";
import { H1, H3, H2 } from "Components/Text";
import { AthleteCard } from "Components/AthleteCard";
import defaultImg from "Assets/Images/default.jpg";
import { Header } from "Components/Header";
import { Footer } from "Components/Footer";
import { SocialFeed } from "Components/SocialFeed";
import {
  WinnerDocument,
  ConfigDocument,
  YearWinnersDocument,
} from "types/types";
import { DateTime } from "luxon";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { pop, popDelay } from "Assets/Animate";

const MotionH1 = motion(H1);

export const Home = () => {
  const [athleteData, setAthleteData] = useState<WinnerDocument[]>();
  const [athleteYearData, setAthleteYearData] =
    useState<YearWinnersDocument[]>();
  const [displayedMonth, setDisplayedMonth] = useState<string>("");
  const [completeSeason, setCompleteSeason] = useState<string>();
  const db = getFirestore(firebaseApp);
  let year = DateTime.now().year;
  const [siteData] = useDocument(
    doc(db, "config/site") as DocumentReference<ConfigDocument>,
  );
  const [winnerByYearDocs] = useCollection(
    query(
      collection(db, "yearWinners") as CollectionReference<YearWinnersDocument>,
    ),
  );

  useEffect(() => {
    const month = siteData?.data()?.currentMonth;
    if (month) {
      setDisplayedMonth(
        DateTime.fromJSDate(month?.toDate()).toFormat("LLLL") +
          " " +
          DateTime.fromJSDate(month?.toDate()).toFormat("yyyy"),
      );
    }
    const season = siteData?.data()?.completeSeason;
    if (season && month) {
      if (DateTime.fromJSDate(month.toDate()).toFormat("LLLL") === "June") {
        setCompleteSeason(DateTime.fromJSDate(season.toDate()).toFormat("yy"));
      } else {
        setCompleteSeason("");
      }
    }
  }, [siteData]);

  const [winnerDocs] = useCollection(
    query(collection(db, "winners") as CollectionReference<WinnerDocument>),
  );
  useEffect(() => {
    if (winnerDocs) {
      let docData = winnerDocs?.docs?.map((doc) => {
        return { id: doc.id, ...doc?.data() };
      });
      setAthleteData(docData);
    }
  }, [winnerDocs]);

  useEffect(() => {
    if (winnerByYearDocs) {
      let docData = winnerByYearDocs?.docs?.map((doc) => {
        return { id: doc.id, ...doc?.data() };
      });
      setAthleteYearData(docData);
    }
  }, [winnerByYearDocs]);

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("animate");
    }
  }, [controls, inView]);

  return (
    <Main>
      <Header />
      <Hero>
        <BackgroundImg src={Background} alt="athlete of the Month. Texas" />
        <LeftImg src={AthleteLeft} />
        <RightImg src={AthleteRight} />
        <CalloutContainer>
          <Callout src={CalloutHeadline} />
          <h1>GREATER TEXAS {year}</h1>
          <TexasLogo src={FordTexasLogo} />
        </CalloutContainer>
      </Hero>
      <CTA>
        <WrapperCTA>
          <MotionH1
            ref={ref}
            animate={controls}
            variants={pop}
            initial="initial"
          >
            Seven Divisions.{" "}
          </MotionH1>
          <MotionH1
            ref={ref}
            variants={popDelay}
            initial="initial"
            animate={controls}
          >
            Seven Monthly Winners.
          </MotionH1>
          <AnimatePresence>
            {/* <ButtonPrimary
              as={Link}
              to={`/nominate/`}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Nominate here
            </ButtonPrimary> */}
          </AnimatePresence>
        </WrapperCTA>
        <WrapperCTA>
          <H3>
            <span>
              Join Greater Texas Ford in recognizing outstanding athletes in our
              state.{" "}
            </span>{" "}
            Each month Greater Texas Ford highlights female high school athletes
            in classes 1A-6A plus eligible private schools.
          </H3>
        </WrapperCTA>
      </CTA>
      {completeSeason ? (
        <AthleteSection>
          <StyledH1>
            20{completeSeason}-20{parseInt(completeSeason) + 1} Athletes of the
            Year
          </StyledH1>
          <AthleteList>
            {athleteYearData
              ?.sort(
                (a: YearWinnersDocument, b: YearWinnersDocument) =>
                  parseInt(b.athleteClassification || 0) -
                  parseInt(a.athleteClassification || 0),
              )
              .map((data: YearWinnersDocument) => {
                const [year] = (data.id || "")?.split("-");
                return (
                  year === completeSeason && (
                    <AthleteCard
                      src={data.athletePhoto || defaultImg}
                      name={data?.athleteName}
                      grade={data?.athleteGrade}
                      sport={data?.athleteSport}
                      school={data?.athleteSchool}
                      category={data?.athleteClassification}
                      details={""}
                    />
                  )
                );
              })}
          </AthleteList>
        </AthleteSection>
      ) : (
        <AthleteSection>
          <StyledH1>{displayedMonth} Athletes of the Month</StyledH1>
          <AthleteList>
            {athleteData
              ?.sort(
                (a: WinnerDocument, b: WinnerDocument) =>
                  parseInt(b.athleteClassification || 0) -
                  parseInt(a.athleteClassification || 0),
              )
              .map((data: WinnerDocument) => {
                let time = data.featuredDate.toMillis();
                return (
                  displayedMonth ===
                    DateTime.fromMillis(time).toFormat("LLLL") +
                      " " +
                      DateTime.fromMillis(time).toFormat("yyyy") && (
                    <AthleteCard
                      src={data.athletePhoto || defaultImg}
                      name={data?.athleteName}
                      grade={data?.athleteGrade}
                      sport={data?.athleteSport}
                      school={data?.athleteSchool}
                      category={data?.athleteClassification}
                      details={data?.athleteProfile || ""}
                    />
                  )
                );
              })}
          </AthleteList>
        </AthleteSection>
      )}

      <H2>Greater Texas Ford Dealers</H2>
      <SocialFeed />
      <Footer />
    </Main>
  );
};
export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;
const Hero = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
const CTA = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1440px;
  background-color: #00142e;
  color: #fff;
  gap: 20px;
  padding: 50px 100px 70px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    padding: 50px 30px;
    gap: 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;
const WrapperCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex: 1;
  span {
    font-family: "F1Bold";
    display: block;
    padding-bottom: 25px;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: center;
    justify-content: center;
  }
`;
const AthleteSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  padding: 50px 70px;
  gap: 40px;
  margin-bottom: 80px;
  border-bottom: 1px solid #c2c2c2;
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 20px;
  }
`;
export const AthleteList = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 35px;
  ${({ theme }) => theme.mediaQueries.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
  }
`;
const StyledH1 = styled(H1)`
  color: #020c29;
  text-align: left;
  ${({ theme }) => theme.mediaQueries.mobile} {
    text-align: center;
    padding-top: 30px;
  }
`;
const BackgroundImg = styled.img`
  width: 100%;
  object-fit: cover;
  ${({ theme }) => theme.mediaQueries.mobile} {
    height: 80vw;
  }
`;
const LeftImg = styled.img`
  width: 35%;
  object-fit: cover;
  position: absolute;
  z-index: 5;
  top: 5%;
  left: 10%;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 50%;
    left: -5%;
    top: 10%;
  }
`;
const RightImg = styled.img`
  width: 35%;
  object-fit: cover;
  position: absolute;
  z-index: 5;
  top: 5%;
  right: 10%;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 50%;
    right: -1%;
    top: 10%;
  }
`;
const CalloutContainer = styled.div`
  width: 40%;
  position: absolute;
  display: grid;
  place-items: center;
  z-index: 10;
  top: 25%;
  margin: 0 auto;
  h1 {
    color: #fff;
    font-family: "F1Bold";
    margin: -2% auto 15px;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 70%;
    top: 30%;
    h1 {
      font-size: min(3vw, 15px);
      margin: 0 auto 10px;
    }
  }
`;
const Callout = styled.img`
  width: 100%;
  object-fit: cover;
  z-index: 10;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 70%;
  }
`;
const TexasLogo = styled.img`
  width: 25%;
  object-fit: cover;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 20%;
  }
`;
